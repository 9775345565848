@import "../../../scss/App.scss";
@import "../../../scss/mixins.scss";

// $widthMain: 1496px;
html,body{
    min-height: 100vh;
    background: $dark;
}

body {
    font: 16px "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1;
    // min-width: $widthMain;
    color: $white;
}

.frame {
    display: flex;
    // padding-right: 20px;
    &__sidebar {
        padding-right: 20px;
    }
    //&__content{
        // padding-right: 1000px;
    //}
}

.page{
    height: 100%;
    &.is-dark{
        background: $dark;
        color: $white;
        border: 1px;
    }
}

// .frame{
//     padding-bottom: 40px;
//     flex: 1 0 auto;
// }

//.inner{
    // max-width: $widthMain;
//}

.btn-f{
    transition: .3s;
    border-radius: 8px;
    height: 48px;
    @include flexcenter;
    font-size: 16px;
    &.is-blue-trans{
        border: 2px solid $blueF;
        color: $blueF;
        cursor: pointer;
        font-weight: 500;
        &:hover{
            background: $dark;
        }
        &:disabled{
            border-color: $grayT;
            color: $grayT;
        }
    }
    &.is-blue{
        font-weight: 500;
        background:$blueF;
        color: $white;
        cursor: pointer;
        &:hover{
            background: $blueDark;
        }
        &:disabled{
            background: $grayT;
            color: $grayT;
        }
    }
}

.page-title{
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.04em;
    color: $white;
    margin-bottom: 24px;
}

.flex-block{
    display: flex;
    // justify-content: flex-end;
    // align-items: center;
    &__block{
        // margin-right: 40px;
        &.is-grow{
            flex-grow: 1;
        }
    }
}

.app-chart__canvas {
    position: relative;
    margin: 0 auto;
    max-height: 230px;
}

.btn-back{
    color: $blueF;
    padding: 12px 20px;
    border-radius: 8px;
    @include flexcenter;
    transition: .3s;
    white-space: nowrap;
    width: min-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    cursor: pointer;
    &:before{
        width: 24px;
        height: 24px;
        @include flexcenter;
        margin-right: 12px;
    }
    &:hover{
        background: $dark;
    }
}

.footer{
    height: 72px;
    background: rgba(54, 58, 84, 0.4);
    @include flexcenter();
    color:$blueF;
    flex: 0 0 auto;
}

@font-face {
	font-family: "IconsFont";
	font-display: swap;
	src: url('../../../assets/fonts/IconsFont/IconsFont.eot?v=368');
	src: url('../../../assets/fonts/IconsFont/IconsFont.eot?#iefix&v=368') format('eot'),
	url('../../../assets/fonts/IconsFont/IconsFont.woff2?v=368') format('woff2'),
	url('../../../assets/fonts/IconsFont/IconsFont.woff?v=368') format('woff'),
	url('../../../assets/fonts/IconsFont/IconsFont.ttf?v=368') format('truetype'),
	url('../../../assets/fonts/IconsFont/IconsFont.svg#IconsFont') format('svg');
}

@mixin icon-styles {
	font-family: "IconsFont";
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none; 
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == 1a_down {
	$char: "\E001";
}
	@if $filename == 1b_up {
	$char: "\E002";
}
	@if $filename == 1c_plus {
	$char: "\E003";
}
	@if $filename == 1d_eye {
	$char: "\E004";
}
	@if $filename == 1e_stat {
	$char: "\E005";
}
	@if $filename == 1f_att {
	$char: "\E006";
}
	@if $filename == 1i_arrow {
	$char: "\E007";
}
	@if $filename == 1j_mode {
	$char: "\E008";
}
	@if $filename == 1k_note {
	$char: "\E009";
}
    @if $filename == 1k_note-active {
	$char: "\E00C";
}
	@if $filename == 1l_profile {
	$char: "\E00A";
}
	@if $filename == 1m_arrow {
	$char: "\E00B";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
	@if $extend {
		@extend %icon;
	} @else {
		@include icon-styles;
	}
	content: icon-char($filename);
}
}

.icon-1a_down {
	@include icon(1a_down);
}
.icon-1b_up {
	@include icon(1b_up);
}
.icon-1c_plus {
	@include icon(1c_plus);
}
.icon-1d_eye {
	@include icon(1d_eye);
}
.icon-1e_stat {
	@include icon(1e_stat);
}
.icon-1f_att {
	@include icon(1f_att);
}
.icon-1i_arrow {
	@include icon(1i_arrow);
}
.icon-1j_mode {
	@include icon(1j_mode);
}
.icon-1k_note {
	@include icon(1k_note);
}
.icon-1k_note-active {
    @include icon(icon-1k_note-active);
}
.icon-1l_profile {
	@include icon(1l_profile);
}
.icon-1m_arrow {
	@include icon(1m_arrow);
}
