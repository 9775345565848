@import "../../../scss/App.scss";

.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.04em;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .cancel-btn {
            cursor: pointer;
            color:$blueF;
            font-weight: 500;
            letter-spacing: 0.02em;
        }

        .add-btn,
        .request-btn {
            cursor: pointer;
            margin-left: 40px;
            padding: 0 26px;
        }
    }
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal-content {
    box-sizing: border-box;
    padding: 20px 60px;
    box-shadow: 4px 16px 24px $shadow;
    border-radius: 16px;
    background-color: $darkL;
    height: 524px;
    width: 960px;
}

.modal-content.upload {
    padding-left: 40px;
    padding-right: 40px;
}

.modal-content.request {
    width: 800px;
    height: 600px;
}

.modal-content.add-news {
    width: 480px;
    height: 496px;
    padding-left: 35px;
    padding-right: 35px;
}

.close-icon {
    cursor: pointer;
}

.modal__container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.input-area {
    border: 1px solid $lightBlue;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 30px;
}

.drop-down {
    box-sizing: border-box;
    position: relative;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: $lightBlue;
        }
    }

    &__body {
        position: absolute;
        background: $dark;
        box-shadow: 4px 8px 8px $shadow;
        border-radius: 4px;
        z-index: 1000;
    }

    &__arrow {
        cursor: pointer;
        filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
    }
}
