@import "../../../../scss/App.scss";

.filter {
    position: absolute;
    right: 0;
    top: 0;
    width: 450px;
    height: 882px;
    background-color: $darkL;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 72px;
    box-shadow: -8px 8px 16px rgba(11, 11, 11, 0.24);

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__main {
        margin: 0 auto;
        padding-top: 30px;
        height: 800px;
    }

    &__menu {
        width: 382px;
        height: 48px;
        border: 1px solid $lightBlue;
        border-radius: 4px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        z-index: 1;

        img {
            cursor: pointer;
            filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
        }
    }

    &__options {
        box-sizing: border-box;
        background-color: $dark;
        border-radius: 4px;
        width: 405px;
        height: fit-content;
        position: absolute;
        top: 200px;
        z-index: 100;
    }

    .regions {
        top: 280px;
    }

    &__option {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 15px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            color: $blueF;
            background-color: #252835;
        }
    }

    &__range-block p, 
    &__menu p {
        font-size: 16px;
        font-weight: 400;
        color: $lightBlue;
        margin: 0;
    }

    &__range-block p {
        padding-bottom: 10px;
    }

    &__range-block {
        margin-bottom: 30px;
        z-index: 1;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
    }

    &__close {
        cursor: pointer;
    }

    &__clear-btn {
        cursor: pointer;
        color: $blueF;
        font-weight: 500;
    }

    &__apply-btn {
        width: 160px;
        height: 48px;
    }

    &__footer {
        justify-self: end;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.range-fields input {
    box-sizing: border-box;
    width: 180px;
    height: 48px;
    border: 1px solid $lightBlue;
    border-radius: 4px;
    padding-left: 15px;
    background-color: transparent;
    outline: none;
    color: inherit;
    font-size: inherit;

    &::placeholder {
        color: $lightBlue;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.range-fields__icon {
    padding: 0 10px;
}

.error-text {
    color: #ff0033;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 0;
    padding-top: 0;
}
