@import "../../../../scss/App.scss";

.drag-and-drop {
    width: 880px;
    height: 292px;
    border: 2px dashed $lightBlue;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 40px;

    p {
        text-align: center;
        color: $lightBlue;
        font-size: 16px;
        line-height: 24px;
        width: 45%;
        margin-bottom: 30px;
    }

    a {
        text-decoration: none;
        width: 207px;
    }
}

.selected-docs-drag {
    width: 400px;
    margin: 0;
    margin-bottom: 60px;
}

.selected-docs-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    height: 350px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.selected-docs-area::-webkit-scrollbar {
    display: none;
}

.drag-and-drop.active {
    background-color: rgba(44, 47, 64, 0.64);
}

.doc {
    width: 400px;
}

.upload-btn {
    cursor: pointer;
    padding: 0 20px;
}
