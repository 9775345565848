@import "./App.scss";
@import "./mixins.scss";
@import "../components/layout/page-layout/PageLayout.scss";
@import "../components/layout/reports/details/documents/Documents.scss";

.block-stat {
    margin-bottom: 20px;
    margin-left: -10px;
    margin-right: 20px;
    padding: 30px;
    padding-left: 40px;
    background: $darkL;
    border-radius: 16px;
    @include shadowDark;
    width: 621px;
    height: 380px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */


    &::-webkit-scrollbar {
        display: none;
    }

    &.is-up {
        background: $darkL url("../assets/img/bl_up.png") no-repeat 100% calc(100% + 10px);
    }

    &.is-down {
        background: $darkL url("../assets/img/bl_down.png") no-repeat 100% calc(100% + 10px);
    }

    &__head {
        &.is-flex {
            display: flex;
            align-items: center;
        }

        &.is-space {
            justify-content: space-between;
        }

        margin-bottom: 18px;
        min-height: 48px;
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.04em;
        width: 296px;
    }

    &__table {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        width: 100%;
        margin-bottom: -8px;

        th {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $grayT;
            text-align: left;
            padding: 6px 0;
            min-width: 98px;

            &:nth-child(1) {
                width: 296px;
                min-width: 296px;
            }
        }

        td {
            text-align: left;
            padding: 8px 0;
        }
    }

    &__btn-link {
        @include flexcenter;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: $blueF;
        padding: 12px 24px;
        cursor: pointer;
        border-radius: 8px;
        width: fit-content;

        &:hover {
            background: $dark;
        }

        &:before {
            font-size: 16px;
            display: inline-block;
            margin-right: 16px;
        }
    }
}

.block-stat_small {
    width: auto;
    height: 300px;
}

.block-stat_medium {
    width: 460px;
    height: 300px;
}

.block-stat_long {
    width: 625px;
    height: 300px;
    
    .doc-list {
        max-height: 376px;
        overflow-y: auto;
        margin-bottom: -40px;
        @include hideScroll();
    }

    .grafic {
        margin-top: 30px;

        &__stat {
            top: 92px;
        }

        &__values {
            margin-top: 24px;
        }
    }
}

.tab-block {
    font-size: 12px;
    letter-spacing: 0.02em;
    height: min-content;

    &.is-flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__btn {
        transition: .3s;
        margin-right: 8px;
        padding: 5px 8px;
        border: 1px solid $blueF;
        color: $blueF;
        border-radius: 16px;
        @include flexcenter;
        cursor: pointer;

        &:hover {
            background: $dark;
        }

        &.is-active {
            color: $white;
            background: $blueF;
        }
    }
}

.rating-name {
    cursor: pointer;
}
