@import "../../../../scss/App.scss";
@import "../../../../scss/mixins.scss";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: $darkL;
    backdrop-filter: blur(40px);
    font-size: 16px;
    margin-bottom: 40px;
    height: 25px;
    z-index: 0;

    &__logo:hover {
        filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
    }    

    &__left {
        width: 180px;
    }

    &__main {
        // flex-grow: 1;
    }

    &__controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    &__btn {
        width: 40px;
        height: 40px;
        @include flexcenter;
        margin-left: 16px;
        cursor: pointer;


        &:before {
            font-size: 18px;
        }

        &.icon-1l_profile:before {
            font-size: 20px;
        }

        &.icon-1k_note:before {
            font-size: 20px;
            
        }

        &.icon-1k_note-active:before {
            font-size: 20px;
        }

        &.icon-1l_profile:hover, &.icon-1k_note:hover {
            background-color: $dark;
            border-radius: 8px;
            color: $blueF;
        }
    }
}

.notification-active {
    position: absolute;
    right: 64px;
    top: 9px;
}

.head-menu {
    height: 100%;

    &__list {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__link {
        position: relative;
        color: $white;
        padding: 0px 56px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -29px;
            height: 4px;
            left: 0;
            background: $blueF;
            width: 0;
            opacity: 0;
            transition: .3s;
        }

        &.is-active,
        &:hover {
            color: $blueF;

            &:after {
                opacity: 1;
                width: 100%;
            }
        }
    }
}
