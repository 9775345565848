@import "../../../../scss/App.scss";

.contractors {
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 40px;
    background-color: $darkL;
    width: 95%;
    height: 110vh;
    border-radius: 16px;
    box-shadow: 2px 8px 8px rgba(11, 11, 11, 0.24);
    padding: 40px;
    padding-bottom: 100px;

    &__main {
        height: 90%;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        padding-bottom: 30px;
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;

        &_left {
            display: flex;
            align-items: center;

            input {
                width: 236px;
                height: 24px;
                background-color: transparent;
                -webkit-appearance: none;
                border: none;
                outline: none;
                color: inherit;
                font-size: 16px;
            }

            input::placeholder {
                color: $grayT;
                font-size: 16px;
            }
        }

        &_left > * {
            margin-right: 30px;
        }
    }

    &__icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        padding: 12px;

        &:hover,
        &.active-filter {
            background-color: $dark;
            border-radius: 8px;
        }
    }

    &__btn {
        box-sizing: border-box;
        padding: 12px 24px;
        border: none;
        font-weight: 500;
        // font-size: 16px;

        &:before {
            font-size: 16px;
            margin-right: 16px;
            display: inline-block;
        }
    }
}

.contractors-table {
    width: 100%;
    border-spacing:0;
    border-collapse: collapse;

    &__head {
        // font-size: 16px;
        background-color: $dark;
        height: 48px;

        th {
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;
            position: relative;

            button {
                border: none;
                font-size: inherit;
                font-family: inherit;
                color: inherit;
                background-color: transparent;
                padding: 0;
                cursor: pointer;
            }
        }
    }

    &__body {
        td {
            padding: 20px 16px;
            border-bottom: 1px solid $lightBlue;
            line-height: 24px;
        }

        tr {
            height: 50px;
        }

        tr:hover {
            background-color: $dark;
            cursor: pointer;
        }
    }
}

.sorting-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 17px;
            height: 17px;

            &.active {
                filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
            }
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 90px;

    img {
        display: block;
        width: 12px;
        height: 12px;
        padding: 20px;
        cursor: pointer;
    }

    &__btns {
        display: flex;
    }

    &__btn {
        background-color: transparent;
        color: inherit;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 20px;
        border: none;
        border-radius: 2px;
        margin: 0 1px;

        &:hover {
            background-color: $blueF;
        } 

        &.active {
            background-color: $blueF;
        }
    }
}