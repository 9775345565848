@import "../../../../../scss/App.scss";
@import "../../../../../scss/mixins.scss";

.table-c-block {
    position: relative;
    line-height: 24px;

    &__bg {
        position: absolute;
        height: 100%;
        width: calc((100% - 196px)/3);
        z-index: 1;

        &.is-green {
            left: 200px;
            background: linear-gradient(180deg, rgba(0, 198, 20, 0.24) 0%, rgba(54, 58, 84, 0.08) 100%);
        }

        &.is-brown {
            left: 466px;
            background: linear-gradient(180deg, rgba(255, 199, 0, 0.24) 0%, rgba(54, 58, 84, 0.08) 100%);
        }

        &.is-blue {
            left: 730px;
            background: linear-gradient(180deg, rgba(102, 150, 255, 0.24) 0%, rgba(54, 58, 84, 0.08) 100%);
        }
    }

    &__table {
        position: relative;
        border-collapse: collapse;
        z-index: 2;

        th {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 30%;
            letter-spacing: 0.02em;
            height: 48px;
            border: 1px solid rgba(44, 47, 64, .8);
            background: rgba(44, 47, 64, .8);

            &:nth-child(1) {
                width: 168px;
                min-width: 168px;
            }

            &.is-upper {
                text-transform: uppercase;
            }
        }

        th,
        td {
            padding: 8px 16px;
            text-align: left;
        }

        td {
            height: 64px;
            border: 1px solid $lightBlue;
            width: calc((100% - 168px) / 3);

            &:nth-child(1) {
                width: 168px;
                min-width: 168px;
            }
        }
    }
}
