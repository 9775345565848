    .block-stat.large {
        width: 1000px;
        height: fit-content;
        padding-left: 40px;
        margin-bottom: 0;
    }

    .flex-block__block {
        margin-right: 0;
    }

    .flex-block{
        margin-top: 40px;
    }
    