@import "../../../../scss/App.scss";

.request-content {
    height: 400px;
    position: relative;
}

.request-dd {
    box-sizing: border-box;
    &__head {
        width: 655px;
        height: 60px;
        font-weight: 400;
        margin-top: 30px;

        p {
            font-size: 18px;
        }
    
        span {
            display: inline-block;
            width: 25px;
        }
    }

    &__body {
        box-sizing: border-box;
        width: 680px;
        height: 280px;
        overflow-y: scroll;
        overflow-x: visible;
        position: absolute;
        top: 100px;
    }

    &__body::-webkit-scrollbar {
        display: none;
    }    

    &__option {
        box-sizing: border-box;
        height: auto;
        padding-bottom: 10px;
        padding-top: 10px;
        cursor: pointer;
        padding-left: 8px;
        background-color: $dark;
    
        input {
            cursor: pointer;
        }
    
        label {
            color: $white;
            font-size: 18px;
            padding-left: 8px;
            margin-right: 20px;
            line-height: 20px;
        }
    }

    &__option:hover {
        background-color: $blueF;
        cursor: pointer;
    }
}

.document {
    padding-left: 5px;
}

.document:first-child {
    padding-left: 0;
}

.request-content__text-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 0;

    textarea {
        box-sizing: border-box;
        width: 710px;
        height: 170px;
        border: 1px solid $lightBlue;
        border-radius: 8px;
        background-color: transparent;
        resize: none;
        outline: $dark;
        padding: 12px 12px 0 12px;
        color: $lightBlue;
        font-size: 20px;
        font-family: "Roboto";
        font-weight: 400;
        margin-top: 40px;
    }

    textarea::placeholder {
        color: $lightBlue;
        font-size: 20px;
        font-family: "Roboto";
        font-weight: 400;
    }
}
