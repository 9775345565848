@import "../../../scss/App.scss";

.notification-wrapper {
    position: fixed;
    right: 20px;
    top: 90px;
    z-index: 2000;
}

.notification-item {
    box-sizing: border-box;
    width: 350px;
    padding: 0 20px;
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $darkL;
    border-radius: 8px;
    margin-bottom: 16px;

    p {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    &__close {
        cursor: pointer;
    }

    &__failure {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        span {
            font-size: 12px;
            line-height: 16px;
            color: #A1A5AE;
        }
    
        p {
            margin: 0; 
            padding-bottom: 10px;
        }
    }
}

.notification-item.success {
    border: 1px solid #00C614;
}

.notification-item.failure {
    border: 1px solid #FF0000;
    height: 108px;
}

.notification-item__left {
    display: flex;
    align-items: center;

    p {
        padding-left: 20px;
    }
}
