@import "../../../../scss/App.scss";

.news-modal {
    position: relative;
}

.news-content {
    &__input-area {
        box-sizing: border-box;
        width: 400px;
        height: 48px;
    }

    &__text-area textarea {
        box-sizing: border-box;
        width: 400px;
        height: 130px;
        border: 1px solid $lightBlue;
        border-radius: 8px;
        background-color: transparent;
        resize: none;
        outline: $dark;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 35px;
        color: $white;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 400;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    &__text-area textarea::placeholder {
        color: $lightBlue;
        font-size: 16px;
    }

    &__dd-head {
        margin-top: 10px;
    }

    &__dd-body {
        width: 400px;
        height: 112px;
        position: absolute;
        top: 52px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    
        p {
            box-sizing: border-box;
            width: 400px;
            height: 32px;
            color: $white;
            font-size: 14px;
            margin: 0;
            padding-left: 15px;
            font-weight: 400;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    
        p:hover {
            color: $blueF;
            background: linear-gradient(0deg, rgba(11, 11, 11, 0.24), rgba(11, 11, 11, 0.24)), #2C2F40;
        }
    }
}

input.news-content__input-area {
    background-color: transparent;
    outline: none;
    font-size: 16px;
    color: $white;
    padding-right: 40px;
}

input.news-content__input-area::placeholder {
    color: $lightBlue;
}

p.news-content__selected-type {
    color: $white;
}

.delete-input,
.delete-textarea {
    display: none;
}

.delete-input.active {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 26px;
    bottom: 267px;
    filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
    z-index: 0;
}

.delete-textarea.active {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 26px;
    bottom: 190px;
    filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
}

.textarea-tooltip, 
.input-tooltip {
    display: none;
}

.textarea-tooltip.active, 
.input-tooltip.active,
.dd-tooltip {
    display: block;
    font-size: 9px;
    width: 100px;
    color: $lightBlue;
    position: absolute;
}

.dd-tooltip { 
    top: 3px;
    left: 11px;
}

.input-tooltip.active {
    width: 180px;
    top: 161px;
    left: 11px;
}

.textarea-tooltip.active {
    bottom: 208px;
    left: 11px;
}
