@import "../../../../scss/App.scss";

.select-wrapper {
    width: 250px;
    font-size: 24px;
    margin-bottom: 30px;
    position: relative;
}

.arrow.hovered {
    filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
}

.select-btn.hovered {
    color: $blueF;
    cursor: pointer;
}

.select-content {
    width: 250px;
    height: fit-content;
    background-color: $dark;
    box-shadow: 4px 8px 8px $shadow;
    border-radius: 4px;
    position: absolute;
    top: 53px;
}

.select-option {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 16px 16px;
    color: $white;
    border: none;
    cursor: pointer;
}

.select-option:hover {
    background: linear-gradient(0deg, $shadow, $shadow), $dark;
    color: $blueF;
}

.select-option:nth-child(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.select-option:nth-child(2) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.select-btn {
    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
}

.select-btn:hover {
    background: $dark;
    border-radius: 8px;
}
