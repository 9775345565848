@import "../../../scss/App.scss";

.lang-dropdown {
    width: 80px;
    height: 40px;
    position: relative;
    border-radius: 8px;

    &__btn {
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    
        img {
            padding-left: 5px;
        }
    }

    &__body {
        border-radius: 4px;
        height: 64px;
        width: 100%;
        position: absolute;
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 4px 8px 8px $shadow;
    
        .option {
            height: 32px;
            width: 100%;
            background-color: $dark;
            padding: 4px 0px;
            border: medium none;
            color: $white;
            padding: 5px 0;
        }
    }

    &__body> * {
        cursor: pointer;
    }

    &__body > *:hover {
        background-color: #252835;
    }

    &__hovered {
        filter: invert(50%) sepia(37%) saturate(4444%) hue-rotate(201deg) brightness(101%) contrast(101%);
    }
}

.lang-dropdown:hover {
    background-color: $dark;
    border-radius: 8px;
    color: $blueF;
}

.option-ru {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.option-en {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
