@import "../../scss/App.scss";

  .main {
  max-width: 432px;
  background: rgba(44, 47, 64, 0.64);
  box-shadow: 4px 8px 12px rgba(11, 11, 11, 0.32);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  text-align: center;
  position: absolute;
  top: calc(50% - 216px);
  left: 0;
  right: 0;
  margin:0 auto;
  }
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .logotext {
    font-family: "Noto Sans";
    font-style: normal;
    color: $white;
    margin: 0 auto;
  }
  #A {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    width: 204px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    margin: 0px auto;
    letter-spacing: 0.04em;
  }
  #B {
    width: 204px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    margin: 0px auto;
    letter-spacing: 0.02em;
  }
  
  .registration_form{
    padding: 10px;
    top: 50%;
  }
  .login {
    width: 320px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #6696FFA3;
    margin: 10px auto;
    background: $dark;
    color: $blueF;
    text-indent: 10px;
  }
  input[type="text"]::-webkit-input-placeholder {
    color: $blueF;
  }
  input[type="password"]::-webkit-input-placeholder {
    color:$blueF;
  }
  #button{
    width: 330px;
    height: 45px;
    margin: 15px auto;
    background: $blueF;
    color: $white;
    font-size: 13px;
    border-radius: 8px;
    font-family: 'Roboto';
  }

  p,a{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $white;
    margin: 0 auto;
  }

  .text_help{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  }
  #link{
  color: $blueF;
  }

@media screen and (max-width: 400px) {
  .main {
    max-width: 280px;
    }
    img{
      width: 40px;
    }
    .registration_form{
      padding: 10px;
      top: 50%;
    }
    .login{
      width: 220px;
      height: 30px;
    }
    #button{
      width: 230px;
      height: 35px;
      font-size: 8px;
    }
}

@media screen and (max-width: 852px) { 
  .main {
    width: 350px;
    position: absolute;
  top: calc(50% - 175px);
  left: 0;
  right: 0;
  margin:0 auto;
    }
    img{
      width: 40px;
    }
    .registration_form{
      padding: 10px;
      top: 50%;
    }
    .login{
      width: 250px;
      height: 30px;
    }
    #button{
      width: 260px;
      height: 35px;
      font-size: 9px;
    }
} 
