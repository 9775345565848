@import "../../../../../scss/App.scss";
@import "../../../../../scss/mixins.scss";

.audit {
    height: auto;

    &__history {
        width: 630px;

        &__big {
            width: 1000px;
        }
    }

    &__progress {
        width: 270px;
    }
}



.grafic {
    position: relative;

    &__values {

        &.is-flex {
            display: flex;
            justify-content: space-between;
        }
    }

    &__val {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: rgba(2555, 255, 255, .4);
        display: flex;
        align-items: center;

        &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $grayT;
            margin-right: 8px;
        }

        &.is-gr {
            &:before {
                background: #B9FF81;
            }

        }

        &.is-bl {
            &:before {
                background: #9CDDFF;
            }

        }

        &.is-wh {
            &:before {
                background: $white;
            }
        }
    }

    &__stat {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.08em;
        color: $white;
    }
}

.select {
    &__title {
        position: relative;
        padding: 12px 16px;
        padding-right: 44px;
        @include flexcenter();
        border-radius: 8px;
        cursor: pointer;

        &:before {
            @include flexcenter();
            font-size: 8px;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 8px;
        }
    }

    &:hover {
        .select__title {
            background: $dark;
            color: $blueF;
        }
    }
}
