@import "../../../../scss/App.scss";
@import "../../../../scss/mixins.scss";

.sidebar{
    background: rgba(54, 58, 84, 0.4);
    box-shadow: 2px 8px 8px $shadow;
    backdrop-filter: blur(40px);
    color: $white;
    padding: 30px;
    width: 250px;
    height: 157.7vh;
    height: 1566px;
    border-radius: 0 16px 16px 0;
    &__text{
        &._type-1{
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 40px;
        }
        &._type-2{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
        &._type-3{
            color: $grayT;
            font-size: 12px;
            font-weight: 300;
        }
    }
    &__text-wr{
        margin-bottom: 16px;
    }
    &__btn-1{
        margin-bottom: 24px;
        margin-top: 56px;
        text-decoration: none;
    }
    &__btn-2{
        &:before{
            font-size: 16px;
            margin-right: 16px;
            display: inline-block;
        }
    }
    .btn-back{
        position: relative;
        left: -24px;
        margin-bottom: 40px;
    }
}
