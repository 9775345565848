$media-1240: 'screen and (max-width: 1240px)'; // 1024
$media-991: 'screen and (max-width: 991px)'; //960,780
$media-767: 'screen and (max-width: 767px)';
$media-640: 'screen and (max-width: 640px)';
$media-520: 'screen and (max-width: 520px)'; // 320
$media-480: 'screen and (max-width: 480px)'; // 320
$media-410: 'screen and (max-width: 410px)'; // 320

$white:#ffffff;
$grafit: #001A34;
$grayBorder: #DBE0E5;
$red:red;
$grayplaceholder: #A2A9AD;
$grayBlue:#B1B9C2;
$green: #6BB431;
$blue: #3389EA;
$lightBlue: rgba(102, 150, 255, 0.64);

$shadow: rgba(11, 11, 11, 0.24);
$dark: #2C2F40;
$darkL: #363A54;
$grayT: #A1A5AE;
$blueF: #6696FF;
$blueDark: #164EC8;
$green: #00C614;
$red: #FF0000;

.App {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
