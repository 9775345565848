@import "../../../../../scss/App.scss";

.no-data {
    display: flex;
    height: 240px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: rgba(102, 150, 255, 0.4);
        font-size: 16px;
        font-weight: 400;
    }

    p.no-data__title {
        text-transform: uppercase;
        color: $lightBlue;
        padding-bottom: 16px;
    }
}
