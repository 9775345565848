@import "../../../../../scss/App.scss";
@import "../../../../../scss/mixins.scss";
@import "../../../page-layout/PageLayout.scss";

.icon-1d_eye {
	@include icon(1d_eye);
}

.doc-list {
    &__item {
        border: 1px solid $lightBlue;
        border-radius: 8px;
        padding: 10px 14px;
        margin-bottom: 24px;
        width: 430px;

        &.is-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.is-green {
            border: 1px solid $green;

            .doc-list__stat {
                color: $green;
            }
        }

        &.is-red {
            border: 1px solid $red;

            .doc-list__stat {
                color: $red;

                &:before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__control {
        display: flex;
        width: 80px;
    }

    &__btn {
        width: 40px;
        height: 40px;
        @include flexcenter;
        color: $blueF;
        cursor: pointer;
        border-radius: 8px;

        &:before {
            font-size: 16px;
        }

        &:hover {
            background: $dark;
        }
    }

    &__name {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }

    &__date,
    &__size {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $grayT;
    }

    &__stat {
        width: 24px;
        margin-right: 16px;
        padding-top: 6px;
        color: $blueF;

        &:before {
            font-size: 16px;
            display: inline-block;
        }
    }
}

.doc-list.is-news {
    .doc-list {
        &__item {
            padding: 16px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &__date {
            margin-top: 4px;
        }
    }
}
