@import "../../../../../scss/mixins.scss";

.rage {
    margin-bottom: 40px;
    margin-top: 18px;
    &__list {
        &.is-flex {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__item {
        box-sizing: border-box;
        margin-right: 40px;
        padding: 40px;
        backdrop-filter: blur(40px);
        border-radius: 16px;
        font-weight: 500;
        text-transform: uppercase;
        // flex-grow: 1;
        @include shadowDark;
        overflow: hidden;
        width: 337px;
        margin-left: -10px;
        

        &.is-red {
            background: url("../../../../../assets/img/rage_r.png") rgba(54, 58, 84, 0.16) no-repeat 10px top;
        }

        &.is-yellow {
            background: url("../../../../../assets/img/rage_y.png") rgba(54, 58, 84, 0.16) no-repeat 10px top;
        }

        &.is-green {
            background: url("../../../../../assets/img/rage_g.png") rgba(54, 58, 84, 0.16) no-repeat 10px top;
        }

        background-size: calc(100% + 10px) calc(100% + 10px) !important;
    }

    &__name {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.04em;
        height: 48px;
    }

    &__val {
        letter-spacing: 0.08em;
    }

    &__type-1 {
        font-size: 52px;
        line-height: 64px;
    }

    &__type-2 {
        font-size: 32px;
        line-height: 48px;
    }
}
